import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'video.js/dist/video-js.css'
// 1. 导入 store 的实例对象
import store from './store/store'
// 分页组件
import Pagination from "@/views/component/pagination.vue";
// import { Dialog} from 'element-ui';
import './utils/pcOrPhone'
import './assets/css/font.css'
import './assets/css/global.css'
import { download } from '@/utils/request'

import 'amfe-flexible'
// import './utils/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'video.js/dist/video-js.css'
// Vue.use(Dialog)
Vue.component('Pagination', Pagination)
Vue.config.productionTip = false
Vue.prototype.download = download
Vue.use(ElementUI);
console.log(store);
store.dispatch('m_user/GetInfo')
store.dispatch('m_user/GetBottomInfo')
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
