// request.js文件
import axios from 'axios';
import store from '../store/store';
import router from '../router';
import errorCode from '@/utils/errorCode'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import { tansParams, blobValidate } from "@/utils/ruoyi";
import { saveAs } from 'file-saver'
// import store from '@/store'
// 引入基础参数文件  和  baseURL配置文件
// import { BASE_URL } from './config'   // baseURL写在config.js文件中
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const request = axios.create({
  baseURL: window.glob.baseURL,
  timeout: 1000000
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    (config.headers.Authorization = store.state.m_user.token),
      console.log(1);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
     // 未设置状态码则默认成功状态
     const code = response.data.code || 200;
     // 获取错误信息
     const msg = errorCode[code] || response.data.msg || errorCode['default']
     // 二进制数据则直接返回
     if(response.request.responseType ===  'blob' || response.request.responseType ===  'arraybuffer'){
       return response.data
     }
    // 对响应数据做点什么
    console.log(1);
    if (response.data.code == 401) {
      Message({
        message: '身份过期或未登录,请重新登录！',
        type: 'warning'
        
      });
      router.push('/login');
    }else if (response.data.code == 500) {
      Message({
        message: '接口服务异常！请联系客服',
        type: 'warning'
      });
    }
    return response;
  },
  function (error) {
    // 对请求错误做些什么
    let { message } = error;
    console.log(1);
    if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(url, params, filename) {
  return request.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
  })
}
export default request;
