import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message.vue')
  },
  
  {
    path: '/lsitFs',
    name: 'list',
    component: () => import('../views/classification/index.vue')
  },
  {
    path: '/classDetail',
    name: 'classDetail',
    component: () => import('../views/classification/classDetail.vue')
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    component: () => import('../views/classification/component/courseDetail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('../views/classification/regist.vue')
  },
  {
    path: '/wzgn',
    name: 'wzgn',
    component: () => import('../views/classification/wzgn.vue')
  },
  {
    path: '/tzgg',
    name: 'tzgg',
    component: () => import('../views/classification/tzgg.vue')
  },
  {
    path: '/zscy',
    name: 'zscy',
    component: () => import('../views/classification/zscy.vue')
  },
  {
    path: '/zxDetil',
    name: 'zxDetil',
    component: () => import('../views/classification/zxDetil.vue')
  },
  {
    path: '/bottomDetil',
    name: 'bottomDetil',
    component: () => import('../views/classification/bottomDetil.vue')
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/car/index.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('../views/car/purchase.vue')
  },
  {
    path: '/orderInformation',
    name: 'orderInformation',
    component: () => import('../views/car/orderInformation.vue')
  },
  
  {
    path: '/toPayOrder',
    name: 'toPayOrder',
    component: () => import('../views/car/toPayOrder.vue')
  },
  {
    path: '/gxPay',
    name: 'gxPay',
    component: () => import('../views/car/gxPay.vue')
  },
  {
    path: '/k',
    name: 'k',
    component: () => import('../views/car/k.vue')
  },
  {
    path: '/yk',
    name: 'yk',
    component: () => import('../views/car/yk.vue')
  },
  {
    path: '/zfb',
    name: 'zfb',
    component: () => import('../views/car/zfb.vue')
  },
  {
    path: '/vx',
    name: 'vx',
    component: () => import('../views/car/vx.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/car/paySuccess.vue')
  },
  {
    path: '/patYundan',
    name: 'patYundan',
    component: () => import('../views/car/patYundan.vue')
   
  },
  
  {
    path: '/studyMain',
    name: 'studyMain',
    component: () => import('../views/calss/studyMain.vue')
  },
  {
    path: '/xszm',
    name: 'xszm',
    component: () => import('../views/calss/component/xszm.vue')
  },
  {
    path: '/studyMain1',
    name: 'studyMain1',
    component: () => import('../views/calss/studyMain1.vue')
  },
  {
    path: '/myResources',
    name: 'myResources',
    component: () => import('../views/calss/component/myResources.vue')
  },
  {
    path: '/fraction',
    name: 'fraction',
    component: () => import('../views/calss/component/fraction.vue')
  },
  {
    path: '/cert',
    name: 'cert',
    component: () => import('../views/calss/component/cert.vue')
  },
  {
    path: '/gx',
    name: 'gx',
    component: () => import('../views/calss/gx.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('../views/calss/resources.vue')
  },
  {
    path: '/resourcesList',
    name: 'resourcesList',
    component: () => import('../views/calss/resourcesList.vue')
  },
  {
    path: '/xcx',
    name: 'xcx',
    component: () => import('../views/calss/xcx.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;
