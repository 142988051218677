import request from '../utils/request';
//登录
export function doLogin(data) {
  return request({
    url: 'user/login.html',
    method: 'post',
    data
  });
}

// 首页数据
export function findIndexInfo(data) {
  return request({
    url: 'web/findIndexInfo.html',
    method: 'post',
    data
  });
}

// 技能分类班级列表
export function toHotClassByCode(data) {
  return request({
    url: '/web/toHotClassByCode.html',
    method: 'post',
    data
  });
}

// 头数据
export function findHeaderInfo(data) {
  return request({
    url: '/web/findHeaderInfo.html',
    method: 'post',
    data
  });
}

// 公需课
export function getTMUrl(data) {
  return request({
    url: '/web/getTMUrl.html',
    method: 'post',
    data
  });
}


// 轮播图详情
export function toGetPicMessage(data) {
  return request({
    url: '/web/toGetPicMessage.html',
    method: 'post',
    data
  });
}

// 底部
export function toGetBottom() {
  return request({
    url: '/web/toGetBottom.html',
    method: 'get',
  });
}

// 底部文字详情
export function toGetMessage(data) {
  return request({
    url: '/web/toGetMessage.html',
    method: 'post',
    data
  });
}

//测试导出
export function exportEnrollData(query) {
  return request({
    url: '/web/exportEnrollData.html',
    method: 'get',
    params:query
  });
}

//获取移动端二维码
export function toAppEwm() {
  return request({
    url: '/web/toAppEwm.html',
    method: 'get',
  });
}