import { findHeaderInfo, toGetBottom } from '@/api/index';

const user = {
  // 为当前模块开启命名空间
  namespaced: true,
  state: {
    logo: {}, //头部数据
    list: [], //头部分类数据
    url: '', //公需课url
    token: localStorage.getItem('token') || '',
    userName: '',
    aboutList: [], //尾部分类点击
    beiAn: [], //尾部备案
    bottomValue: [], //尾部客服信息
    gzhEwm: [], //尾部公众号二维码
    realName:'',//用户名
    isLogin:'',//登录状态
  },

  mutations: {
    HEAD_LOGO: (state, logo) => {
      state.logo = logo;
    },
    CODE_LIST: (state, list) => {
      state.list = list;
    },
    TM_URL: (state, url) => {
      state.url = url;
    },
    AB_OUT: (state, aboutList) => {
      state.aboutList = aboutList;
    },
    BEI_AN: (state, beiAn) => {
      state.beiAn = beiAn;
    },
    BOTTOM_VALUE: (state, bottomValue) => {
      state.bottomValue = bottomValue;
    },
    GZH_EWM: (state, gzhEwm) => {
      state.gzhEwm = gzhEwm;
    },
    REAL_NAME: (state, realName) => {
      state.realName = realName;
    }, 
    // 更新 token 字符串
    updateToken(state, token) {
      state.token = token;
    },
    // 更新 token 字符串
    updateName(state, userName) {
      state.userName = userName;
    },
    UPDATA_ISLOGIN(state, isLogin) {
      state.isLogin = isLogin;
    },
  },

  actions: {
    // 获取头部信息
    GetInfo({ commit, state }) {
      let data = {
        token: localStorage.getItem('token') || ''
      };
      return new Promise((resolve, reject) => {
        findHeaderInfo(data)
          .then(res => {
            if (res.data.data.isLogin == '01') {
              localStorage.removeItem('userName1');
              commit('UPDATA_ISLOGIN',res.data.data.isLogin);
            }
            commit('HEAD_LOGO', res.data.data.hearderLogo);
            commit('CODE_LIST', res.data.data.pCodeList);
            commit('TM_URL', res.data.data.tmUrl);
            commit('REAL_NAME', res.data.data.realName);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //获取信息 尾部
    GetBottomInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        toGetBottom()
          .then(res => {
            console.log(res);
            commit('AB_OUT', res.data.data.aboutList);
            commit('BEI_AN', res.data.data.beiAn);
            commit('BOTTOM_VALUE', res.data.data.bottomValue);
            commit('GZH_EWM', res.data.data.gzhEwm);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default user;
