<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="less">
* {
  box-sizing: border-box;
  font-family: Regular;
}
input {
  outline:none;
}
.el-notification {
  cursor: pointer;
}
#app {
}

</style>
